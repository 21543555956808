/* You can add global styles to this file, and also import other style files */

// ANGULAR MATERIAL INCLUDE
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* GOOGLE FONTS  */
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue:100,200,300,400,500,600,700,800,900|Nunito:100,200,300,400,500,600,700,800,900|Source+Sans+Pro:100,200,300,400,500,600,700,800,900|Raleway:100,200,300,400,500,600,700,800,900&display=swap');

/* BOOTSTRAP */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* FONTAWESOME */
@import '@fortawesome/fontawesome-free/css/all.min.css';

// /* GSAP */
// @import 'gsap/dist/gsap.js';

/*===============================================================================
GLOBAL STYLING ==================================================================
===============================================================================*/
body, html {
    background: var(--white);
    scroll-behavior: unset !important;
}
mat-toolbar {
    background: transparent !important;
}
mat-toolbar-row {
    background: transparent !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
}
.spacer {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}
mat-form-field {
    height: auto !important;
    width: 100% !important;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
}
/*===============================================================================
MAT DIALOG CONTAINER ============================================================
===============================================================================*/
.custom-dialog-container mat-dialog-container {
    background: var(--white);
    display: block;
    padding-top: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    padding-left: 7px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    pointer-events: auto;
}
.darkBackground {
    background: var(--black) !important;
    opacity: 0.9 !important;
}
:root {
    --blackest: #000000;
    --blacker: #080808;
    --black: #101010;
    --lightblack: #181818;
    --lighterblack: #202020;
    --lightestblack: #282828;
    --darkestgray: #303030;
    --darkergray: #383838;
    --darkgray: #404040;
    --lightdarkgray: #484848;
    --lighterdarkgray: #505050;
    --lightestdarkgray: #585858;
    --grayest: #808080;
    --grayer: #808080;
    --gray: #808080;
    --lightgray: #888888;
    --lightergray: #909090;
    --lightestgray: #989898;
    --dirtiestwhite: #DCDCDC;
    --dirtierwhite: #F0F0F0;
    --dirtywhite: #F8F8F8;
    --white: #FFFFFF;
    --blue: #2B5A93;
    --darkestblue: #171717;
    --darkblue: #0A2437;
    --pink: #FF6978;
}
@media (max-width: 768px) {
    .custom-dialog-container mat-dialog-container {
        background: var(--white);
        display: block;
        padding-top: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        border-radius: 4px;
        box-sizing: border-box;
        box-shadow: none;
        overflow: auto;
        outline: 0;
        width: 100%;
        height: 100%;
        min-height: inherit;
        max-height: inherit;
        pointer-events: auto;
    }
}
.jumbotron-header {
    background: url('/assets/img/photos/wallpaper.jpg');
    background-size: cover;
    background-position: 50% 100%;
    height: auto;
    width: 100%;
    margin: 120px 0px 0px 0px;
    padding: 15vh 0px 20vh 0px;
    @media (max-width: 992px) {
        margin: 120px 0px 0px 0px;
        padding: 10vh 0px 20vh 0px;
    }
    @media (max-width: 768px) {
        background-position: 0% 100%;
        margin: 90px 0px 0px 0px;
        padding: 50px 0px 75px 0px;
    }
    h1 {
        font-family: 'Bebas Neue', cursive;
        font-size: 80px;
        font-weight: normal;
        font-style: normal;
        text-decoration: none;
        letter-spacing: 0.07em;
        word-spacing: 0.00em;
        font-variant: normal;
        line-height: 100%;
        color: var(--white);
        text-align: left;
        text-transform: uppercase;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        @media (max-width: 992px) {
            font-size: 70px;
        }
        @media (max-width: 768px) {
            font-size: 30px;
        }
    }
    p {
        font-family: 'Bebas Neue', cursive;
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        text-decoration: none;
        letter-spacing: 0.07em;
        word-spacing: 0.00em;
        font-variant: normal;
        line-height: 100%;
        color: var(--white);
        text-align: left;
        text-transform: normal;
        margin: 50px 0px 0px 0px;
        @media (max-width: 992px) {
            font-size: 24px;
            margin: 30px 0px 0px 0px;
        }
        @media (max-width: 768px) {
            font-size: 16px;
            margin: 10px 0px 0px 0px;
        }
    }
}